import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/Layout"

const Privacy = ({ location }) => {
  const lang = "en"

  // Page setting
  const pageTitle = "Privacy Policy"
  const pageSlug = "privacy"
  const pageKeywords = [`Privacy Policy`]
  const pageDescription = "Misawaya Privacy Policy"
  const pageImage = ""
  const email = "info@misawaya.net"

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={pageTitle}
      pageSlug={pageSlug}
      pageKeywords={pageKeywords}
      pageDescription={pageDescription}
      pageImage={pageImage}
    >
      <div className="page-title-wrapper">
        <h1 className="page-title container-narrow">{pageTitle}</h1>
      </div>
      <article className="privacy-content">
        <div className="container-narrow">
          <div className="content__item">
            <p>Thanks for visiting. Misawaya respects your privacy. </p>
            <p style={{ marginBottom: "1rem" }}>
              This Privacy Statement applies to the website{" "}
              <Link to="/" className="link-ul">
                https://misawaya.org/
              </Link>
              . By using any of Misawaya's Services, you confirm you have agreed
              to this Privacy Policy.
            </p>
            <p>
              We will revise these policies as necessary. Information after the
              revision will be posted on our website.
            </p>
          </div>
          <h2 className="content__head">Personal Information</h2>
          <div className="content__item">
            <p>
              We only collect personal information that is necessary to run
              Misawaya, fulfil our obligations to you.
            </p>
          </div>
          <h2 className="content__head">
            How we use your personal information
          </h2>
          <div className="content__item">
            <p style={{ marginBottom: "1rem" }}>
              We use your information for the following purposes:
              <br />
              1. Replying email
              <br />
              2. Communicating with you through Newsletter
            </p>
            <p>
              We will not sell your personal information to any third parties or
              external organizations.{" "}
            </p>
            We do not share personally identifiable information including your
            name and email address with other companies for marketing purposes
            unless the user agreed to that sharing or required to do so by law.
          </div>
          <h2 className="content__head">Google Analytics</h2>
          <div className="content__item">
            <p>
              We use{" "}
              <a
                href="https://marketingplatform.google.com/about/analytics/"
                target="_blank"
                rel="noopener noreferrer"
                className="link-ul"
              >
                Google Analytics
              </a>{" "}
              to help us understand how our services are used, but no personally
              identifiable data including your name or email address is shared.{" "}
              <a
                href="https://marketingplatform.google.com/about/analytics/terms/us/"
                target="_blank"
                rel="noopener noreferrer"
                className="link-ul"
              >
                Click to see Google Analytics Terms of Service
              </a>
              . You can review{" "}
              <a
                href="https://privacy.google.com/businesses/compliance/"
                target="_blank"
                rel="noopener noreferrer"
                className="link-ul"
              >
                Google's compliance page here
              </a>
              .
            </p>
          </div>

          <h2 className="content__head">How to contact us</h2>
          <div className="content__item">
            <p>
              If you have any queries about our privacy policy, please email{" "}
              <a href={"mailto:" + email} className="link-ul">
                {email}
              </a>
            </p>
          </div>
        </div>
      </article>
    </Layout>
  )
}

export default Privacy
